export default {
    getDateFormat(f) {
        const d = new Date(f);
        return (
          ("0" + d.getDate()).slice(-2) +
          "-" +
          ("0" + (d.getMonth() + 1)).slice(-2) +
          "-" +
          d.getFullYear() +
          " " +
          ("0" + d.getHours()).slice(-2) +
          ":" +
          ("0" + d.getMinutes()).slice(-2)
        );
      },
      getOnlyDateFormat(f) {
        const d = new Date(f);
        return (
          ("0" + d.getDate()).slice(-2) +
          "-" +
          ("0" + (d.getMonth() + 1)).slice(-2) +
          "-" +
          d.getFullYear() 
        );
      },
      mes(f) {
        const meses = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        return meses[new Date(f).getUTCMonth()];
      },
      formatDate(date) {
        if (!date) {
          return null;
        }
        const [year, month, day] = date.split("-");
        return `${year}-${month}-${day}`;
      },
      parseDate(date) {
        if (!date) {
          return null;
        }
        // console.log(typeof date)
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      
}