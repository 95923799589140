export const FETCH_STADIUMS_REQUEST = 'FETCH_STADIUMS_REQUEST'
export const FETCH_STADIUMS_SUCCESS = 'FETCH_STADIUMS_SUCCESS'
export const FETCH_STADIUMS_FAILURE = 'FETCH_STADIUMS_FAILURE'

export const FETCH_CONTINENTS_REQUEST = 'FETCH_CONTINENTS_REQUEST'
export const FETCH_CONTINENTS_SUCCESS = 'FETCH_CONTINENTS_SUCCESS'
export const FETCH_CONTINENTS_FAILURE = 'FETCH_CONTINENTS_FAILURE'

export const FETCH_GROUP_PHASES_REQUEST = 'FETCH_GROUP_PHASES_REQUEST'
export const FETCH_GROUP_PHASES_SUCCESS = 'FETCH_GROUP_PHASES_SUCCESS'
export const FETCH_GROUP_PHASES_FAILURE = 'FETCH_GROUP_PHASES_FAILURE'


export const FETCH_POSITIONS_REQUEST = 'FETCH_POSITIONS_REQUEST'
export const FETCH_POSITIONS_SUCCESS = 'FETCH_POSITIONS_SUCCESS'
export const FETCH_POSITIONS_FAILURE = 'FETCH_POSITIONS_FAILURE'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCES = 'UPLOAD_IMAGE_SUCCES'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'

export const GET_MATCH_RESULTS_REQUEST = 'GET_MATCH_RESULTS_REQUEST'
export const GET_MATCH_RESULTS_SUCCESS = 'GET_MATCH_RESULTS_SUCCESS'
export const GET_MATCH_RESULTS_FAILURE = 'GET_MATCH_RESULTS_FAILURE'

export const FETCH_POLLA_SCOPES_REQUEST = 'FETCH_POLLA_SCOPES_REQUEST'
export const FETCH_POLLA_SCOPES_SUCCESS = 'FETCH_POLLA_SCOPES_SUCCESS'
export const FETCH_POLLA_SCOPES_FAILURE = 'FETCH_POLLA_SCOPES_FAILURE'

export const FETCH_AWARD_POSITIONS_REQUEST = 'FETCH_AWARD_POSITIONS_REQUEST'
export const FETCH_AWARD_POSITIONS_SUCCESS = 'FETCH_AWARD_POSITIONS_SUCCESS'
export const FETCH_AWARD_POSITIONS_FAILURE = 'FETCH_AWARD_POSITIONS_FAILURE'