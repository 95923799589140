export const FETCH_POLLA_CALLEJERA_REQUEST = 'FETCH_POLLA_CALLEJERA_REQUEST'
export const FETCH_POLLA_CALLEJERA_SUCCESS = 'FETCH_POLLA_CALLEJERA_SUCCESS'
export const FETCH_POLLA_CALLEJERA_FAILURE = 'FETCH_POLLA_CALLEJERA_FAILURE'


export const GET_POLLA_CALLEJERA_BY_ID_REQUEST = 'GET_POLLA_CALLEJERA_BY_ID_REQUEST'
export const GET_POLLA_CALLEJERA_BY_ID_SUCCESS = 'GET_POLLA_CALLEJERA_BY_ID_SUCCESS'
export const GET_POLLA_CALLEJERA_BY_ID_FAILURE = 'GET_POLLA_CALLEJERA_BY_ID_FAILURE'


export const ADD_POLLA_CALLEJERA_REQUEST = 'ADD_POLLA_CALLEJERA_REQUEST'
export const ADD_POLLA_CALLEJERA_SUCCESS = 'ADD_POLLA_CALLEJERA_SUCCESS'
export const ADD_POLLA_CALLEJERA_FAILURE = 'ADD_POLLA_CALLEJERA_FAILURE'

export const EDIT_POLLA_CALLEJERA_REQUEST = 'EDIT_POLLA_CALLEJERA_REQUEST'
export const EDIT_POLLA_CALLEJERA_SUCCESS = 'EDIT_POLLA_CALLEJERA_SUCCESS'
export const EDIT_POLLA_CALLEJERA_FAILURE = 'EDIT_POLLA_CALLEJERA_FAILURE'

export const FETCH_POLLAS_REQUEST = 'FETCH_POLLAS_REQUEST'
export const FETCH_POLLAS_SUCCESS = 'FETCH_POLLAS_SUCCESS'
export const FETCH_POLLAS_FAILURE = 'FETCH_POLLAS_FAILURE'

export const FETCH_POSITIONS_BY_POLLA_REQUEST = 'FETCH_POSITIONS_BY_POLLA_REQUEST'
export const FETCH_POSITIONS_BY_POLLA_SUCCESS = 'FETCH_POSITIONS_BY_POLLA_SUCCESS'
export const FETCH_POSITIONS_BY_POLLA_FAILURE = 'FETCH_POSITIONS_BY_POLLA_FAILURE'

export const ADD_POLLA_PAYMENT_REQUEST = 'ADD_POLLA_PAYMENT_REQUEST'
export const ADD_POLLA_PAYMENT_SUCCESS = 'ADD_POLLA_PAYMENT_SUCCESS'
export const ADD_POLLA_PAYMENT_FAILURE = 'ADD_POLLA_PAYMENT_FAILURE'