export const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST'
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS'
export const FETCH_COUPONS_FAILURE = 'FETCH_COUPONS_FAILURE'


export const GET_COUPON_BY_ID_REQUEST = 'GET_COUPON_BY_ID_REQUEST'
export const GET_COUPON_BY_ID_SUCCESS = 'GET_COUPON_BY_ID_SUCCESS'
export const GET_COUPON_BY_ID_FAILURE = 'GET_COUPON_BY_ID_FAILURE'

export const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST'
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS'
export const ADD_COUPON_FAILURE = 'ADD_COUPON_FAILURE'

export const EDIT_COUPON_REQUEST = 'EDIT_COUPON_REQUEST'
export const EDIT_COUPON_SUCCESS = 'EDIT_COUPON_SUCCESS'
export const EDIT_COUPON_FAILURE = 'EDIT_COUPON_FAILURE'