import * as types from '../types/user'
import APIuser from '@/services/user'


const defaultState = {
    users: [],
    user: {},
    userError: {},
    userSusccesMessage: null
}



const mutations = {

    [types.FETCH_USERS_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
    },
    [types.FETCH_USERS_SUCCESS](state, users) {
        state.fetchingData = false
        state.userError = null
        state.users = users
    },
    [types.FETCH_USERS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
    },
    [types.ADD_USER_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
        state.userSusccesMessage = null
    },
    [types.ADD_USER_SUCCESS](state, { user, message }) {
        state.fetchingData = false
        state.userError = null
        state.userSusccesMessage = message
    },
    [types.ADD_USER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
        state.userSusccesMessage = null
    },
    [types.EDIT_USER_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
        state.userSusccesMessage = null
    },
    [types.EDIT_USER_SUCCESS](state, { user, message }) {

        state.fetchingData = false
        state.userError = null
        state.userSusccesMessage = message
    },
    [types.EDIT_USER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
        state.userSusccesMessage = null
    },
    [types.GET_USER_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
    },
    [types.GET_USER_BY_ID_SUCCESS](state, { user, message }) {
        state.fetchingData = false
        state.userError = null
        state.user = user
    },
    [types.GET_USER_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
    },
    [types.FETCH_USERS_COMPANY_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
    },
    [types.FETCH_USERS_COMPANY_SUCCESS](state, users) {
        state.fetchingData = false
        state.userError = null
        state.users = users
    },
    [types.FETCH_USERS_COMPANY_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
    },
    [types.ADD_COMPANY_USER_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
        state.userSusccesMessage = null
    },
    [types.ADD_COMPANY_USER_SUCCESS](state, { user, message }) {
        state.fetchingData = false
        state.userError = null
        state.userSusccesMessage = message
    },
    [types.ADD_COMPANY_USER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
        state.userSusccesMessage = null
    },
    [types.EDIT_COMPANY_USER_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
        state.userSusccesMessage = null
    },
    [types.EDIT_COMPANY_USER_SUCCESS](state, { user, message }) {
        state.fetchingData = false
        state.userError = null
        state.userSusccesMessage = message
    },
    [types.EDIT_COMPANY_USER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
        state.userSusccesMessage = null
    },
    [types.GET_COMPANY_USER_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
    },
    [types.GET_COMPANY_USER_BY_ID_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.userError = null
        state.user = data
    },
    [types.GET_COMPANY_USER_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
    },
    [types.DELETE_COMPANY_USER_REQUEST](state) {
        state.fetchingData = true;
        state.userError = null;
    },
    [types.DELETE_COMPANY_USER_SUCCESS](state, { message }) {
        state.fetchingData = false;
        state.userError = null;
        state.userSusccesMessage = message;
    },
    [types.DELETE_COMPANY_USER_FAILURE](state, { error }) {
        state.fetchingData = false;
        state.userError = error;
        state.userSusccesMessage = null;
    },
    [types.ADD_COMPANY_REQUEST] (state) {
        state.fetchingData = true;
        state.userError = null;
        state.userSusccesMessage = null;
    },
    [types.ADD_COMPANY_SUCCESS] (state, { message }) {
        state.fetchingData = false;
        state.userError = null;
        state.userSusccesMessage = message;
    },
    [types.ADD_COMPANY_FAILURE] (state, { error }) {
        state.fetchingData = false;
        state.userError = error;
        state.userSusccesMessage = null;
    },
    [types.EDIT_COMPANY_REQUEST] (state) {
        state.fetchingData = true;
        state.userError = null;
        state.userSusccesMessage = null;
    },
    [types.EDIT_COMPANY_SUCCESS] (state, { message }) {
        state.fetchingData = false;
        state.userError = null;
        state.userSusccesMessage = message;
    },
    [types.EDIT_COMPANY_FAILURE] (state, { error }) {
        state.fetchingData = false;
        state.userError = error;
        state.userSusccesMessage = null;
    },
    [types.GET_COMPANY_BY_ID_REQUEST] (state) {
        state.fetchingData = true;
        state.userError = null;
    },
    [types.GET_COMPANY_BY_ID_SUCCESS] (state, { data, message }) {
        state.fetchingData = false;
        state.userError = null;
        state.user = data;
    },
    [types.GET_COMPANY_BY_ID_FAILURE] (state, { error }) {
        state.fetchingData = false;
        state.userError = error;
    }
}

const actions = {
    fetchUsers({ commit }, { params }) {
        commit(types.FETCH_USERS_REQUEST)
        APIuser.getUsers(params)
            .then((response) => commit(types.FETCH_USERS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_USERS_FAILURE, { error: err.response.data }))
    },
    addUser({ commit }, { user }) {
        commit(types.ADD_USER_REQUEST)
        APIuser.storeUser(user)
            .then((response) => commit(types.ADD_USER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_USER_FAILURE, { error: err.response.data }))
    },
    getUserById({ commit }, { id }) {
        commit(types.GET_USER_BY_ID_REQUEST)
        APIuser.getUserById(id)
            .then((response) => commit(types.GET_USER_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_USER_BY_ID_FAILURE, { error: err.response.data }))
    },
    editUser({ commit }, { user }) {
        commit(types.EDIT_USER_REQUEST)
        APIuser.editUser(user)
            .then((response) => commit(types.EDIT_USER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_USER_FAILURE, { error: err.response.data }))
    },
    fetchUsersCompany({ commit }, { params }) {
        commit(types.FETCH_USERS_COMPANY_REQUEST)
        APIuser.getCompanyUsers(params)
            .then((response) => commit(types.FETCH_USERS_COMPANY_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_USERS_COMPANY_FAILURE, { error: err.response.data }))
    },
    addCompanyUser({ commit }, { user }) {
        commit(types.ADD_COMPANY_USER_REQUEST)
        APIuser.storeCompanyUser(user)
            .then((response) => commit(types.ADD_COMPANY_USER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_COMPANY_USER_FAILURE, { error: err.response.data }))
    },
    getCompanyUserById({ commit }, { id }) {
        commit(types.GET_COMPANY_USER_BY_ID_REQUEST)
        APIuser.getCompanyUserById(id)
            .then((response) => commit(types.GET_COMPANY_USER_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_COMPANY_USER_BY_ID_FAILURE, { error: err.response.data }))
    },
    editCompanyUser({ commit }, { user }) {
        commit(types.EDIT_COMPANY_USER_REQUEST)
        APIuser.editCompanyUser(user)
            .then((response) => commit(types.EDIT_COMPANY_USER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_COMPANY_USER_FAILURE, { error: err.response.data }))
    },
    deleteCompanyUser({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            commit(types.DELETE_COMPANY_USER_REQUEST)
            APIuser.deleteCompanyUser(id)
                .then((response) => {
                    commit(types.DELETE_COMPANY_USER_SUCCESS, { ...response.data })
                    resolve(response)
                })
                .catch((err) => {
                    commit(types.DELETE_COMPANY_USER_FAILURE, { error: err.response.data })
                    reject(err)
                })
        })
    },
    addCompany({ commit }, { company }) {
        commit(types.ADD_COMPANY_REQUEST)
        APIuser.storeCompany(company)
            .then((response) => commit(types.ADD_COMPANY_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_COMPANY_FAILURE, { error: err.response.data }))
    },
    editCompany({ commit }, { company }) {
        commit(types.EDIT_COMPANY_REQUEST)
        APIuser.editCompany(company)
            .then((response) => commit(types.EDIT_COMPANY_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_COMPANY_FAILURE, { error: err.response.data }))
    },
    getCompanyById({ commit }, { id }) {
        commit(types.GET_COMPANY_BY_ID_REQUEST)
        APIuser.getCompanyById(id)
            .then((response) => commit(types.GET_COMPANY_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_COMPANY_BY_ID_FAILURE, { error: err.response.data }))
    }
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

