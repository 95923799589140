export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const FETCH_USERS_COMPANY_REQUEST = 'FETCH_USERS_COMPANY_REQUEST'
export const FETCH_USERS_COMPANY_SUCCESS = 'FETCH_USERS_COMPANY_SUCCESS'
export const FETCH_USERS_COMPANY_FAILURE = 'FETCH_USERS_COMPANY_FAILURE'

export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE'

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE'

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'

export const ADD_COMPANY_USER_REQUEST = 'ADD_COMPANY_USER_REQUEST'
export const ADD_COMPANY_USER_SUCCESS = 'ADD_COMPANY_USER_SUCCESS'
export const ADD_COMPANY_USER_FAILURE = 'ADD_COMPANY_USER_FAILURE'

export const EDIT_COMPANY_USER_REQUEST = 'EDIT_COMPANY_USER_REQUEST'
export const EDIT_COMPANY_USER_SUCCESS = 'EDIT_COMPANY_USER_SUCCESS'
export const EDIT_COMPANY_USER_FAILURE = 'EDIT_COMPANY_USER_FAILURE'

export const GET_COMPANY_USER_BY_ID_REQUEST = 'GET_COMPANY_USER_BY_ID_REQUEST'
export const GET_COMPANY_USER_BY_ID_SUCCESS = 'GET_COMPANY_USER_BY_ID_SUCCESS'
export const GET_COMPANY_USER_BY_ID_FAILURE = 'GET_COMPANY_USER_BY_ID_FAILURE'

export const DELETE_COMPANY_USER_REQUEST = 'DELETE_COMPANY_USER_REQUEST'
export const DELETE_COMPANY_USER_SUCCESS = 'DELETE_COMPANY_USER_SUCCESS'
export const DELETE_COMPANY_USER_FAILURE = 'DELETE_COMPANY_USER_FAILURE'

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST'
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS'
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE'

export const EDIT_COMPANY_REQUEST = 'EDIT_COMPANY_REQUEST'
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS'
export const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE'

export const GET_COMPANY_BY_ID_REQUEST = 'GET_COMPANY_BY_ID_REQUEST'
export const GET_COMPANY_BY_ID_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS'
export const GET_COMPANY_BY_ID_FAILURE = 'GET_COMPANY_BY_ID_FAILURE'