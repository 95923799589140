import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeCoupon(data) {
        return axios.post(`${ENDPOINT_PATH}/coupons/`, data);
    },
    getCoupons(params = null) {
        return axios.post(`${ENDPOINT_PATH}/coupons/datatable`, params);
    },
    getCouponById(id) {
        return axios.get(`${ENDPOINT_PATH}/coupons/${id}`)
    },
    editCoupon(coupon) {
        return axios.put(`${ENDPOINT_PATH}/coupons/${coupon.id}`, coupon)
    }
};