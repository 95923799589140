const DefaultRoute = {
    'admin': '/',
    'company': '/empresa-resumen'
}


DefaultRoute.install = function (Vue) {
    Vue.prototype.$getConst = (key) => {
        return DefaultRoute[key];
    }
};

export default DefaultRoute;