import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeUser(data) {
        return axios.post(`${ENDPOINT_PATH}/users`, data);
    },
    getUsers(params = null) {
        return axios.post(`${ENDPOINT_PATH}/users/datatable`, params);
    },
    getUserById(id) {
        return axios.get(`${ENDPOINT_PATH}/users/${id}`)
    },
    editUser(user) {
        return axios.put(`${ENDPOINT_PATH}/users/${user.id}`, user)
    },
    getCompanyUsers(params = null) {
        return axios.post(`${ENDPOINT_PATH}/users/company/user/datatable`, params);
    },
    storeCompanyUser(data) {
        return axios.post(`${ENDPOINT_PATH}/users/company/user`, data);
    },
    getCompanyUserById(id) {
        return  axios.get(`${ENDPOINT_PATH}/users/company/user/${id}`)
    },
    editCompanyUser(user) {
        return axios.put(`${ENDPOINT_PATH}/users/company/user/${user.id}`, user)
    },
    deleteCompanyUser(id) {
        return axios.delete(`${ENDPOINT_PATH}/users/company/user/${id}`)
    },
    storeCompany(data) {
        return axios.post(`${ENDPOINT_PATH}/users/company`, data);
    },
    editCompany(company) {
        return axios.put(`${ENDPOINT_PATH}/users/company/${company.id}`, company)
    },
    getCompanyById(id) {
        return axios.get(`${ENDPOINT_PATH}/users/company/${id}`)
    }

};
