import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
   
    getPurchases(params = null, polla_type_id) {
        return axios.post(`${ENDPOINT_PATH}/purchase/datatable?polla_type_id=${polla_type_id}`, params);
    },
    exportPurchase(params= null, polla_type_id){
        return axios.post(`${ENDPOINT_PATH}/purchase/export?polla_type_id=${polla_type_id}`, params ,{
            responseType: "arraybuffer"
        });
    }
};
