import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeTeam(data) {
        return axios.post(`${ENDPOINT_PATH}/teams/`, data);
    },
    getTeams(params = null) {
        return axios.post(`${ENDPOINT_PATH}/teams/datatable`, params);
    },
    getTeamById(id) {
        return axios.get(`${ENDPOINT_PATH}/teams/${id}`)
    },
    editTeam(team) {
        return axios.put(`${ENDPOINT_PATH}/teams/${team.id}`, team)
    },
    getAllTeams(){
        return axios.get(`${ENDPOINT_PATH}/teams`)
    },
    getPlayerByTeamId(id){
        return axios.get(`${ENDPOINT_PATH}/teams/${id}/players`)
    }
};
