import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getStadiums() {
        return axios.get(`${ENDPOINT_PATH}/masters/stadiums`);
    },
    getContinents() {
        return axios.get(`${ENDPOINT_PATH}/masters/continents`);
    },
    getGroupPhases() {
        return axios.get(`${ENDPOINT_PATH}/masters/group-phase`);
    },
    getPositions(){
        return axios.get(`${ENDPOINT_PATH}/masters/positions`);
    },
    uploadImage(data){
        return axios.post(`${ENDPOINT_PATH}/resources/upload/images`, data);
    },
    getMatchResults() {
        return axios.get(`${ENDPOINT_PATH}/masters/match-results`);
    },
    getPollaScopes(){
        return axios.get(`${ENDPOINT_PATH}/masters/scope-pollas`);
    },
    getAwardPositions(){
        return axios.get(`${ENDPOINT_PATH}/masters/award-positions`);
    }
};
