import * as types from '../types/group'
import APIgroup from '@/services/group'

const defaultState = {
    groups: [],
    maxTG: 4,
    group: {
        name : '',
        players: [],
    },
    groupError: {},
    groupSusccesMessage: null
}


const mutations = {

    [types.FETCH_GROUPS_REQUEST](state) {
        state.fetchingData = true
        state.groupError = null
    },
    [types.FETCH_GROUPS_SUCCESS](state, groups) {
        // console.log(data)
        state.fetchingData = false
        state.groupError = null
        state.groups = groups
    },
    [types.FETCH_GROUPS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.groupError = error
    },
    [types.ADD_GROUP_REQUEST](state) {
        state.fetchingData = true
        state.groupError = null
        state.groupSusccesMessage = null
    },
    [types.ADD_GROUP_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.groupError = null
        state.groupSusccesMessage = message
    },
    [types.ADD_GROUP_FAILURE](state, { error }) {
        state.fetchingData = false
        state.groupError = error
        state.groupSusccesMessage = null
    },
    [types.EDIT_GROUP_REQUEST](state) {
        state.fetchingData = true
        state.groupError = null
        state.groupSusccesMessage = null
    },
    [types.EDIT_GROUP_SUCCESS](state, { data, message }) {

        state.fetchingData = false
        state.groupError = null
        state.groupSusccesMessage = message
    },
    [types.EDIT_GROUP_FAILURE](state, { error }) {
        state.fetchingData = false
        state.groupError = error
        state.groupSusccesMessage = null
    },
    [types.GET_GROUP_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.groupError = null
    },
    [types.GET_GROUP_BY_ID_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.groupError = null
        const { group_teams} = data;
        if(group_teams){
            delete data.group_teams;
            const lnGT= group_teams.length;
            if(lnGT < state.maxTG){
                for (let i=0; i < state.maxTG - lnGT; i++){
                    group_teams.push({
                        key: i,
                        team_id: null,
                    })
                }
            }
            data.group_teams = group_teams;
        }
        state.group = data
    },
    [types.GET_GROUP_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.groupError = error
    },

}


const actions = {
    fetchGroups({ commit }, { params }) {
        commit(types.FETCH_GROUPS_REQUEST)
        APIgroup.getGroups(params)
            .then((response) => commit(types.FETCH_GROUPS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_GROUPS_FAILURE, { error: err.response.data }))
    },
    addGroup({ commit }, { group }) {
        commit(types.ADD_GROUP_REQUEST)
        APIgroup.storeGroup(group)
            .then((response) => commit(types.ADD_GROUP_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_GROUP_FAILURE, { error: err.response.data }))
    },
    getGroupById({ commit }, { id }) {
        commit(types.GET_GROUP_BY_ID_REQUEST)
        APIgroup.getGroupById(id)
            .then((response) => commit(types.GET_GROUP_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_GROUP_BY_ID_FAILURE, { error: err.response.data }))
    },
    editGroup({ commit }, { group }) {
        commit(types.EDIT_GROUP_REQUEST)
        APIgroup.editGroup(group)
            .then((response) => commit(types.EDIT_GROUP_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_GROUP_FAILURE, { error: err.response.data }))
    },
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

