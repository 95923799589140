export const FETCH_AWARDS_REQUEST = 'FETCH_AWARDS_REQUEST'
export const FETCH_AWARDS_SUCCESS = 'FETCH_AWARDS_SUCCESS'
export const FETCH_AWARDS_FAILURE = 'FETCH_AWARDS_FAILURE'


export const GET_AWARD_BY_ID_REQUEST = 'GET_AWARD_BY_ID_REQUEST'
export const GET_AWARD_BY_ID_SUCCESS = 'GET_AWARD_BY_ID_SUCCESS'
export const GET_AWARD_BY_ID_FAILURE = 'GET_AWARD_BY_ID_FAILURE'

export const ADD_AWARD_REQUEST = 'ADD_AWARD_REQUEST'
export const ADD_AWARD_SUCCESS = 'ADD_AWARD_SUCCESS'
export const ADD_AWARD_FAILURE = 'ADD_AWARD_FAILURE'

export const EDIT_AWARD_REQUEST = 'EDIT_AWARD_REQUEST'
export const EDIT_AWARD_SUCCESS = 'EDIT_AWARD_SUCCESS'
export const EDIT_AWARD_FAILURE = 'EDIT_AWARD_FAILURE'


export const DELETE_AWARD_REQUEST = 'DELETE_AWARD_REQUEST'
export const DELETE_AWARD_SUCCESS = 'DELETE_AWARD_SUCCESS'
export const DELETE_AWARD_FAILURE = 'DELETE_AWARD_FAILURE'
