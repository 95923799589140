import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    dark: false,
    themes: {
      light: {
        primary: '#1F6B9D',
        primary2 : '#083C5F',
        accent: colors.grey.darken3,
        header: '#299F7E',
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
        textcolor: '#083C5F',
        textcolor2: colors.shades.black,
        colorWhite: colors.shades.white,
        sidenav: 'F2F2F2',
        success2: '#00ad59',
        bgColor: colors.shades.white
      },
      dark: {
        primary: '#1F6B9D',
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: '#c59400',
        error: '#b92500',
        success: colors.green.accent3,
        textcolor: colors.shades.white,
        textcolor2: '#e5e5e5',
        colorWhite: '#d6d6d6',
        success2: '#008645',
        bgColor: '#121212'
      }
    },
  },
});
