export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST'
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS'
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE'

export const GET_GROUP_BY_ID_REQUEST = 'GET_GROUP_BY_ID_REQUEST'
export const GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS'
export const GET_GROUP_BY_ID_FAILURE = 'GET_GROUP_BY_ID_FAILURE'

export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST'
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS'
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE'

export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST'
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS'
export const EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE'
