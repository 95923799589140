import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
// import Home from '../views/Home.vue'
import store from '@/store/index'
import DefaultRoute from '@/helpers/default-routes.js';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: Home,
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      // roles: ['admin']
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        // component: Home,
        component: () => import('@/views/dashboard/Index.vue'),
        meta: {
          requiresAuth: true,
          title: 'Resumen',
          id: 'home',
          roles: ['admin']
        }
      },
      {
        path: 'datos',
        name: 'datos',
        props: true,
        component: () => import('@/views/data/Index.vue'),
        meta: {
          title: 'Datos',
          requiresAuth: true,
          id: 'equipos',
          breadCrumb: [
            {
              text: 'Datos'
            }
          ]
        },
        children: [
          {
            path: '',
            name: 'equipos-index',
            props: true,
            component: () => import('@/views/team/Index.vue'),
            meta: {
              title: 'Equipos',
              requiresAuth: true,
              id: 'equipos-index',
              roles: ['admin'],
            },
            children: [
              {
                path: '',
                name: 'equipos',
                props: true,
                component: () => import('@/views/team/Teams.vue'),
                meta: {
                  title: 'Equipos',
                  requiresAuth: true,
                  id: 'equipos-list',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos'
                      },
                      {
                        text: 'Equipos'
                      }
                    ]
                  }
                },
              },
              {
                path: 'add',
                name: 'equipos-add',
                props: true,
                component: () => import('@/views/team/TeamAddEdit.vue'),
                meta: {
                  title: 'Agregar Equipo',
                  requiresAuth: true,
                  id: 'equipos-add',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/'
                      },
                      {
                        text: 'Agregar Equipo'
                      }
                    ]
                  }
                }
              },
              {
                path: 'edit/:id',
                name: 'equipos-edit',
                props: true,
                component: () => import('@/views/team/TeamAddEdit.vue'),
                meta: {
                  title: 'Editar Equipo',
                  requiresAuth: true,
                  id: 'equipos-edit',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/'
                      },
                      {
                        text: 'Editar Equipo'
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            path: 'grupos',
            name: 'grupos-index',
            props: true,
            component: () => import('@/views/group/Index.vue'),
            meta: {
              title: 'Grupos',
              requiresAuth: true,
              id: 'groups-general',
              roles: ['admin']
            },
            children: [
              {
                path: '',
                name: 'grupos',
                props: true,
                component: () => import('@/views/group/Groups.vue'),
                meta: {
                  title: 'Grupos',
                  requiresAuth: true,
                  id: 'equipos-list',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/grupos'
                      },
                      {
                        text: 'Grupos'
                      }
                    ]
                  }
                },
              },
              {
                path: 'add',
                name: 'grupos-add',
                props: true,
                component: () => import('@/views/group/GroupAddEdit.vue'),
                meta: {
                  title: 'Agregar Grupo',
                  requiresAuth: true,
                  id: 'grupos-add',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/grupos'
                      },
                      {
                        text: 'Agregar Grupo'
                      }
                    ]
                  }
                }
              },
              {
                path: 'edit/:id',
                name: 'grupos-edit',
                props: true,
                component: () => import('@/views/group/GroupAddEdit.vue'),
                meta: {
                  title: 'Editar Grupo',
                  requiresAuth: true,
                  id: 'grupos-edit',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/grupos'
                      },
                      {
                        text: 'Editar Grupo'
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            path: 'partidos',
            name: 'partidos-index',
            props: true,
            component: () => import('@/views/match/Index.vue'),
            meta: {
              title: 'Partidos',
              requiresAuth: true,
              id: 'partidos-general',
              roles: ['admin']
            },
            children: [
              {
                path: '',
                name: 'partidos',
                props: true,
                component: () => import('@/views/match/Matches.vue'),
                meta: {
                  title: 'Partidos',
                  requiresAuth: true,
                  id: 'partidos-list',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/partidos'
                      },
                      {
                        text: 'Partidos'
                      }
                    ]
                  }
                },
              },
              {
                path: 'add',
                name: 'partidos-add',
                props: true,
                component: () => import('@/views/match/MatchAddEdit.vue'),
                meta: {
                  title: 'Agregar Partido',
                  requiresAuth: true,
                  id: 'partidos-add',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/partidos'
                      },
                      {
                        text: 'Agregar Partido'
                      }
                    ]
                  }
                }
              },
              {
                path: 'edit/:id',
                name: 'partidos-edit',
                props: true,
                component: () => import('@/views/match/MatchAddEdit.vue'),
                meta: {
                  title: 'Editar Partido',
                  requiresAuth: true,
                  id: 'partidos-edit',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Datos',
                        to: '/datos/partidos'
                      },
                      {
                        text: 'Editar Partido'
                      }
                    ]
                  }
                }
              }
            ]
          }

        ]
      },
      {
        path: 'noticias',
        name: 'notificas',
        props: true,
        component: () => import('@/views/news/Index.vue'),
        meta: {
          title: 'Noticias',
          requiresAuth: true,
          id: 'noticias-general',
          // breadCrumb: [
          //   {
          //     text: 'Notificas'
          //   }
          // ]
        },
        children: [
          {
            path: '',
            name: 'notificias',
            props: true,
            component: () => import('@/views/news/News.vue'),
            meta: {
              title: 'Noticias',
              requiresAuth: true,
              id: 'noticias-list',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Noticias'
                  }
                ]
              }
            },
          },
          {
            path: 'add',
            name: 'noticias-add',
            props: true,
            component: () => import('@/views/news/NewsAddEdit.vue'),
            meta: {
              title: 'Agregar Noticia',
              requiresAuth: true,
              id: 'noticias-add',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Noticias',
                    to: '/noticias'
                  },
                  {
                    text: 'Agregar Noticia'
                  }
                ]
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'noticias-edit',
            props: true,
            component: () => import('@/views/news/NewsAddEdit.vue'),
            meta: {
              title: 'Editar Noticia',
              requiresAuth: true,
              id: 'noticias-edit',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Noticias',
                    to: '/noticias'
                  },
                  {
                    text: 'Editar Noticia'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'cupones',
        name: 'cupones',
        props: true,
        component: () => import('@/views/coupon/Index.vue'),
        meta: {
          title: 'Cupones',
          requiresAuth: true,
          id: 'cupones-general',
          // breadCrumb: [
          //   {
          //     text: 'Notificas'
          //   }
          // ]
        },
        children: [
          {
            path: '',
            name: 'cupones',
            props: true,
            component: () => import('@/views/coupon/Coupons.vue'),
            meta: {
              title: 'Cupones',
              requiresAuth: true,
              id: 'cupones-list',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Cupones'
                  }
                ]
              }
            },
          },
          {
            path: 'add',
            name: 'cupones-add',
            props: true,
            component: () => import('@/views/coupon/CouponAddEdit.vue'),
            meta: {
              title: 'Agregar Cupon',
              requiresAuth: true,
              id: 'cupones-add',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Cupones',
                    to: '/cupones'
                  },
                  {
                    text: 'Agregar Cupon'
                  }
                ]
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'cupones-edit',
            props: true,
            component: () => import('@/views/coupon/CouponAddEdit.vue'),
            meta: {
              title: 'Editar Cupon',
              requiresAuth: true,
              id: 'noticias-edit',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Cupones',
                    to: '/cupones'
                  },
                  {
                    text: 'Editar Cupon'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        props: true,
        component: () => import('@/views/user/Index.vue'),
        meta: {
          title: 'Usuarios',
          requiresAuth: true,
          id: 'usuarios-general',
          roles: ['admin']
        },
        children: [
          {
            path: '',
            name: 'usuarios',
            props: true,
            component: () => import('@/views/user/Users.vue'),
            meta: {
              title: 'Usuarios',
              requiresAuth: true,
              id: 'usuarios-list',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Usuarios',
                  }
                ]
              }
            }
          },{
            path: 'add-empresa',
            name: 'usuarios-add-empresa',
            props: true,
            component: () => import('@/views/user/CompanyAddEdit.vue'),
            meta: {
              title: 'Agregar Empresa',
              requiresAuth: true,
              id: 'usarios-empresa-add',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Usuarios',
                    to: '/usuarios'
                  },
                  {
                    text: 'Agregar Empresa'
                  }
                ]
              }
            }
          },
          {
            path: 'edit-empresa/:id',
            name: 'usuarios-edit-empresa',
            props: true,
            component: () => import('@/views/user/CompanyAddEdit.vue'),
            meta: {
              title: 'Editar Empresa',
              requiresAuth: true,
              id: 'usuarios-edit-empresa',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Usuarios',
                    to: '/usuarios'
                  },
                  {
                    text: 'Editar Empresa'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'polla-patas',
        name: 'polla-patas',
        props: true,
        component: () => import('@/views/polla-patas/IndexMain.vue'),
        meta: {
          title: 'Polla Patas',
          requiresAuth: true,
          id: 'polla-patas-general',
          roles: ['admin']
        },
        children: [
          {
            path: '',
            name: 'polla-patas',
            props: true,
            component: () => import('@/views/polla-patas/PollaPatas.vue'),
            meta: {
              title: 'Polla Patas',
              requiresAuth: true,
              id: 'polla-patas-list',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Polla Patas',
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'puntaje',
        name: 'puntaje',
        props: true,
        component: () => import('@/views/rules/Index.vue'),
        meta: {
          title: 'Puntaje',
          requiresAuth: true,
          id: 'puntaje-general',
          roles: ['admin']
        },
        children: [
          {
            path: '',
            name: 'puntaje',
            props: true,
            component: () => import('@/views/rules/Rules.vue'),
            meta: {
              title: 'Puntaje',
              requiresAuth: true,
              id: 'puntaje-list',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Puntaje',
                  }
                ]
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'puntaje-edit',
            props: true,
            component: () => import('@/views/rules/RuleEdit.vue'),
            meta: {
              title: 'Editar Noticia',
              requiresAuth: true,
              id: 'puntaje-edit',
              roles: ['admin'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Puntaje',
                    to: '/puntaje'
                  },
                  {
                    text: 'Editar Puntaje'
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'polla-callejera',
        name: 'polla-callejera',
        props: true,
        component: () => import('@/views/polla/IndexMain.vue'),
        meta: {
          title: 'Polla Callejera',
          requiresAuth: true,
          id: 'polla-callejera',
          breadCrumb: [
            {
              text: 'Polla de Patas'
            }
          ]
        },
        children: [
          {
            path: '',
            name: 'polla-callejera-index',
            props: true,
            component: () => import('@/views/polla/IndexPolla.vue'),
            meta: {
              title: 'Polla Callejera',
              requiresAuth: true,
              id: 'polla-callejera-index',
              roles: ['admin'],
            },
            children: [
              {
                path: '',
                name: 'pollas',
                props: true,
                component: () => import('@/views/polla/PollaCallejera.vue'),
                meta: {
                  title: 'pollas',
                  requiresAuth: true,
                  id: 'polla-list',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Polla Callejera',
                        to: '/polla-callejera'
                      },
                      {
                        text: 'Polla'
                      }
                    ]
                  }
                },
              },
              {
                path: 'add',
                name: 'polla-add',
                props: true,
                component: () => import('@/views/polla/PollaAddEdit.vue'),
                meta: {
                  title: 'Agregar Polla',
                  requiresAuth: true,
                  id: 'polla-add',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Polla Callejera',
                        to: '/polla-callejera'
                      },
                      {
                        text: 'Agregar Polla'
                      }
                    ]
                  }
                }
              },
              {
                path: 'edit/:id',
                name: 'polla-edit',
                props: true,
                component: () => import('@/views/polla/PollaAddEdit.vue'),
                meta: {
                  title: 'Editar Equipo',
                  requiresAuth: true,
                  id: 'polla-edit',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Polla Callejera',
                        to: '/polla-callejera/'
                      },
                      {
                        text: 'Editar Polla'
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            path: 'posiciones',
            name: 'polla-posiciones-index',
            props: true,
            component: () => import('@/views/polla/IndexPositions.vue'),
            meta: {
              title: 'Posiciones',
              requiresAuth: true,
              id: 'polla-callejera-posiciones',
              roles: ['admin'],
            },
            children: [
              {
                path: '',
                name: 'polla-posiciones',
                props: true,
                component: () => import('@/views/polla/Posiciones.vue'),
                meta: {
                  title: 'Posiciones',
                  requiresAuth: true,
                  id: 'polla-posiciones-list',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Polla Callejera',
                        to: '/polla-callejera/posiciones'
                      },
                      {
                        text: 'Posiciones'
                      }
                    ]
                  }
                },
              },
            ]
          }, {
            path: 'ventas',
            name: 'polla-ventas-index',
            props: true,
            component: () => import('@/views/polla/IndexVentas.vue'),
            meta: {
              title: 'Ventas',
              requiresAuth: true,
              id: 'polla_callejera-ventas',
              roles: ['admin']
            },
            children: [
              {
                path: '',
                name: 'polla-ventas',
                props: true,
                component: () => import('@/views/polla/Ventas.vue'),
                meta: {
                  title: 'Ventas',
                  requiresAuth: true,
                  id: 'polla-ventas-list',
                  roles: ['admin'],
                  breadCrumb(route) {
                    return [
                      {
                        text: 'Polla Callejera',
                        to: '/polla-callejera/ventas'
                      },
                      {
                        text: 'Ventas'
                      }
                    ]
                  }
                },
              },
            ]
          }

        ]
      },
      {
        path: 'empresa-resumen',
        name: 'empresa-resumen',
        props: true,
        component: () => import('@/views/company/home/Index.vue'),
        meta: {
          title: 'Resumen',
          requiresAuth: true,
          id: 'company-general',
          roles: ['company']
        },
        children: [
          {
            path: '',
            name: 'company-resumen',
            props: true,
            component: () => import('@/views/company/home/Positions.vue'),
            meta: {
              title: 'Resumen',
              requiresAuth: true,
              id: 'company-list',
              roles: ['company'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Resumen',
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'empresa-premios',
        name: 'empresa-premios',
        props: true,
        component: () => import('@/views/company/award/Index.vue'),
        meta: {
          title: 'Premios',
          requiresAuth: true,
          id: 'company-award-general',
        },
        children: [
          {
            path: '',
            name: 'empresa-premios',
            props: true,
            component: () => import('@/views/company/award/Awards.vue'),
            meta: {
              title: 'Premios',
              requiresAuth: true,
              id: 'premios-list',
              roles: ['company'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Premios'
                  }
                ]
              }
            },
          },
          {
            path: 'add',
            name: 'empresa-premios-add',
            props: true,
            component: () => import('@/views/company/award/AwardAddEdit.vue'),
            meta: {
              title: 'Agregar Premio',
              requiresAuth: true,
              id: 'premios-add',
              roles: ['company'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Premios',
                    to: '/empresa-premios'
                  },
                  {
                    text: 'Agregar Premio'
                  }
                ]
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'empresa-premios-edit',
            props: true,
            component: () => import('@/views/company/award/AwardAddEdit.vue'),
            meta: {
              title: 'Editar Premio',
              requiresAuth: true,
              id: 'premios-edit',
              roles: ['compnay'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Premios',
                    to: '/empresa-premios'
                  },
                  {
                    text: 'Editar Premio'
                  }
                ]
              }
            }
          }
        ]
      },

      {
        path: 'empresa-usuarios',
        name: 'empresa-usuarios',
        props: true,
        component: () => import('@/views/company/user/Index.vue'),
        meta: {
          title: 'Usuarios',
          requiresAuth: true,
          id: 'company-user-general',
        },
        children: [
          {
            path: '',
            name: 'empresa-usuarios',
            props: true,
            component: () => import('@/views/company/user/Users.vue'),
            meta: {
              title: 'Usuarios',
              requiresAuth: true,
              id: 'empresa-usuarios-list',
              roles: ['company'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Usuarios'
                  }
                ]
              }
            },
          },
          {
            path: 'add',
            name: 'empresa-usuarios-add',
            props: true,
            component: () => import('@/views/company/user/UserAddEdit.vue'),
            meta: {
              title: 'Agregar Usuario',
              requiresAuth: true,
              id: 'empresa-usuarios-add',
              roles: ['company'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Usuarios',
                    to: '/empresa-usuarios'
                  },
                  {
                    text: 'Agregar Usuario'
                  }
                ]
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'empresa-usuarios-edit',
            props: true,
            component: () => import('@/views/company/user/UserAddEdit.vue'),
            meta: {
              title: 'Editar Usuario',
              requiresAuth: true,
              id: 'empresa-usuarios-edit',
              roles: ['compnay'],
              breadCrumb(route) {
                return [
                  {
                    text: 'Usuarios',
                    to: '/empresa-usuarios'
                  },
                  {
                    text: 'Editar Usuario'
                  }
                ]
              }
            }
          }
        ]
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/privacy-protection-policies',
    name: 'privacy',
    component: () => import('@/views/PrivacyPolicies.vue'),
    meta: {
      title: 'Privacy Policies',
    }
  },
  { path: '*', component: () => import('@/components/layout/404.vue') }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active"
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn()) {
      // console.log(store.getters.isLoggedIn())
      // return next()

      const { roles } = to.meta;
      if (roles.includes(store.getters.userSession.role)) {
        return next()
      } else {
        return next({ path: DefaultRoute[store.getters.userSession.role] })
      }
    }
    next('/login')
  } else {
    next()
  }
})

export default router
