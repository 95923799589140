import * as types from '../types/news'
import APInews from '@/services/news'

const defaultState = {
    arrayNews: [],
    news: {
    },
    newsError: {},
    newsSusccesMessage: null
}


const mutations = {

    [types.FETCH_NEWS_REQUEST](state) {
        state.fetchingData = true
        state.newsError = null
    },
    [types.FETCH_NEWS_SUCCESS](state, arrayNews) {
        // console.log(data)
        state.fetchingData = false
        state.newsError = null
        state.arrayNews = arrayNews
    },
    [types.FETCH_NEWS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.newsError = error
    },
    [types.ADD_NEWS_REQUEST](state) {
        state.fetchingData = true
        state.newsError = null
        state.newsSusccesMessage = null
    },
    [types.ADD_NEWS_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.newsError = null
        state.newsSusccesMessage = message
    },
    [types.ADD_NEWS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.newsError = error
        state.newsSusccesMessage = null
    },
    [types.EDIT_NEWS_REQUEST](state) {
        state.fetchingData = true
        state.newsError = null
        state.newsSusccesMessage = null
    },
    [types.EDIT_NEWS_SUCCESS](state, { data, message }) {

        state.fetchingData = false
        state.newsError = null
        state.newsSusccesMessage = message
    },
    [types.EDIT_NEWS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.newsError = error
        state.newsSusccesMessage = null
    },
    [types.GET_NEWS_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.newsError = null
    },
    [types.GET_NEWS_BY_ID_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.newsError = null
        state.news = data
    },
    [types.GET_NEWS_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.newsError = error
    },

}


const actions = {
    fetchNews({ commit }, { params }) {
        commit(types.FETCH_NEWS_REQUEST)
        APInews.getNews(params)
            .then((response) => commit(types.FETCH_NEWS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_NEWS_FAILURE, { error: err.response.data }))
    },
    addNews({ commit }, { news }) {
        commit(types.ADD_NEWS_REQUEST)
        APInews.storeNews(news)
            .then((response) => commit(types.ADD_NEWS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_NEWS_FAILURE, { error: err.response.data }))
    },
    getNewsById({ commit }, { id }) {
        commit(types.GET_NEWS_BY_ID_REQUEST)
        APInews.getNewsById(id)
            .then((response) => commit(types.GET_NEWS_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_NEWS_BY_ID_FAILURE, { error: err.response.data }))
    },
    editNews({ commit }, { news }) {
        commit(types.EDIT_NEWS_REQUEST)
        APInews.editNews(news)
            .then((response) => commit(types.EDIT_NEWS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_NEWS_FAILURE, { error: err.response.data }))
    },
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

