import * as types from '../types/award'
import APIaward from '@/services/award'


const defaultState = {
    arrayAwards: [],
    award: {
    },
    awardError: {},
    awardSusccesMessage: null
}


const mutations= {
    [types.FETCH_AWARDS_REQUEST](state) {
        state.fetchingData = true
        state.awardError = null
    },
    [types.FETCH_AWARDS_SUCCESS](state, arrayAwards) {
        // console.log(data)
        state.fetchingData = false
        state.awardError = null
        state.arrayAwards = arrayAwards
    },
    [types.FETCH_AWARDS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.awardError = error
    },
    [types.ADD_AWARD_REQUEST](state) {
        state.fetchingData = true
        state.awardError = null
        state.awardSusccesMessage = null
    },
    [types.ADD_AWARD_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.awardError = null
        state.awardSusccesMessage = message
    },
    [types.ADD_AWARD_FAILURE](state, { error }) {
        state.fetchingData = false
        state.awardError = error
        state.awardSusccesMessage = null
    },
    [types.EDIT_AWARD_REQUEST](state) {
        state.fetchingData = true
        state.awardError = null
        state.awardSusccesMessage = null
    },
    [types.EDIT_AWARD_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.awardError = null
        state.awardSusccesMessage = message
    },
    [types.EDIT_AWARD_FAILURE](state, { error }) {
        state.fetchingData = false
        state.awardError = error
        state.awardSusccesMessage = null
    },
    [types.GET_AWARD_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.awardError = null
        state.awardSusccesMessage = null
    },
    [types.GET_AWARD_BY_ID_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.awardError = null
        state.award = data;
    },
    [types.GET_AWARD_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.awardError = error
    },
    [types.DELETE_AWARD_REQUEST](state) {
        state.fetchingData = true
        state.awardError = null
        state.awardSusccesMessage = null
    },
    [types.DELETE_AWARD_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.awardError = null
        state.awardSusccesMessage = message
    },
    [types.DELETE_AWARD_FAILURE](state, { error }) {
        state.fetchingData = false
        state.awardError = error
        state.awardSusccesMessage = null
    }
}

const actions = {
    fetchAwards({ commit },{params, polla_id}) {
        commit(types.FETCH_AWARDS_REQUEST)
        APIaward.getAwards(params, polla_id)
        .then((response) => commit(types.FETCH_AWARDS_SUCCESS, { ...response.data }))
        .catch((err) => commit(types.FETCH_AWARDS_FAILURE, { error: err.response.data }))
    },
    addAward ({ commit }, { award }) {
        commit(types.ADD_AWARD_REQUEST)
        APIaward.storeAward(award)
            .then((response) => commit(types.ADD_AWARD_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_AWARD_FAILURE, { error: err.response.data }))
    },
    getAwardById({ commit }, { id }) {
        commit(types.GET_AWARD_BY_ID_REQUEST)
        APIaward.getAwardById(id)
            .then((response) => commit(types.GET_AWARD_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_AWARD_BY_ID_FAILURE, { error: err.response.data }))
    },
    editAward({ commit }, { award }) {
        commit(types.EDIT_AWARD_REQUEST)
        APIaward.editAward(award)
            .then((response) => commit(types.EDIT_AWARD_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_AWARD_FAILURE, { error: err.response.data }))
    },
    deleteAward({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            commit(types.DELETE_AWARD_REQUEST)
            APIaward.deleteAward(id)
                .then((response) => {
                    commit(types.DELETE_AWARD_SUCCESS, { ...response.data })
                    resolve(response)
                })
                .catch((err) => {
                    commit(types.DELETE_AWARD_FAILURE, { error: err.response.data })
                    reject(err)
                })
        })
    }
}


export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

