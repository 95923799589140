import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getRules(params = null) {
        return axios.post(`${ENDPOINT_PATH}/polla-rules-default/datatable`, params);
    },
    getRulesById(id) {
        return axios.get(`${ENDPOINT_PATH}/polla-rules-default/${id}`)
    },
    editRules(rule) {
        return axios.put(`${ENDPOINT_PATH}/polla-rules-default/${rule.id}`, rule)
    }
};
