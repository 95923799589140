import * as types from '../types/team'
import APIteam from '@/services/team'
import APIgroup from '@/services/group'

const defaultState = {
    teams: [],
    selectTeams: [],
    maxMatch: 5,
    team: {
        name: '',
        players: [],
        team_matches: []
    },
    teamError: {},
    teamSusccesMessage: null
}


const mutations = {

    [types.FETCH_TEAMS_REQUEST](state) {
        state.fetchingData = true
        state.teamError = null
    },
    [types.FETCH_TEAMS_SUCCESS](state, teams) {
        // console.log(data)
        state.fetchingData = false
        state.teamError = null
        state.teams = teams
    },
    [types.FETCH_TEAMS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.teamError = error
    },
    [types.ADD_TEAM_REQUEST](state) {
        state.fetchingData = true
        state.teamError = null
        state.teamSusccesMessage = null
    },
    [types.ADD_TEAM_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.teamError = null
        state.teamSusccesMessage = message
    },
    [types.ADD_TEAM_FAILURE](state, { error }) {
        state.fetchingData = false
        state.teamError = error
        state.teamSusccesMessage = null
    },
    [types.EDIT_TEAM_REQUEST](state) {
        state.fetchingData = true
        state.teamError = null
        state.teamSusccesMessage = null
    },
    [types.EDIT_TEAM_SUCCESS](state, { data, message }) {

        state.fetchingData = false
        state.teamError = null
        state.teamSusccesMessage = message
    },
    [types.EDIT_TEAM_FAILURE](state, { error }) {
        state.fetchingData = false
        state.teamError = error
        state.teamSusccesMessage = null
    },
    [types.GET_TEAM_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.teamError = null
    },
    [types.GET_TEAM_BY_ID_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.teamError = null
        const { team_matches } = data
        if (team_matches) {
            delete data.team_matches;
            const lnM = team_matches.length;
            if (lnM < state.maxMatch) {
                for (let i = 0; i < state.maxMatch - lnM; i++) {
                    team_matches.push({
                        key: i,
                        team_goals: "",
                        second_team_name: "",
                        second_team_goals: "",
                        competition: ""
                    })
                }
            }
            data.team_matches = team_matches
        }
        state.team = data

    },
    [types.GET_TEAM_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.teamError = error
    },
    [types.FETCH_ALL_TEAMS_REQUEST](state) {
        state.fetchingData = true
        state.teamError = null
    },
    [types.FETCH_ALL_TEAMS_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.teamError = null
        state.selectTeams = data
    },
    [types.FETCH_ALL_TEAMS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.teamError = error
    },
    [types.GET_TEAMS_BY_GROUP_ID_REQUEST](state) {
        state.fetchingData = true
        state.teamError = null
    },
    [types.GET_TEAMS_BY_GROUP_ID_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.teamError = null
        state.selectTeams = data
    },
    [types.GET_TEAMS_BY_GROUP_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.teamError = error
    },
    [types.SET_TEAM_EMPTY](state){
        state.team = {
            name: '',
            players: [],
            team_matches: []
        }
    },
    [types.EDIT_TEAM_PROPERTY](state,{property,value}){
        console.log(value);
        state.team[property] = value
    },
    [types.ADD_TEAM_PLAYER_PROPERTY](state,{team}){
        state.team.players.push(team)
    },
    [types.REMOVE_TEAM_PLAYER_PROPERTY](state,{index}){
        state.team.players.splice(index,1)
    },
    [types.EDIT_TEAM_PLAYER_PROPERTY](state,{index,property,value}){
        state.team.players[index][property] = value
    },
    [types.EDIT_TEAM_MATCHES_PROPERTY](state,{index,property,value}){
        state.team.team_matches[index][property] = value
    },
    [types.GET_PLAYERS_BY_TEAM_ID_REQUEST](state) {
        state.fetchingData = true
        state.teamError = null
    },
    [types.GET_PLAYERS_BY_TEAM_ID_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.teamError = null
        // state.team.players = data
    },
    [types.GET_PLAYERS_BY_TEAM_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.teamError = error
    }
}


const actions = {
    fetchTeams({ commit }, { params }) {
        commit(types.FETCH_TEAMS_REQUEST)
        APIteam.getTeams(params)
            .then((response) => commit(types.FETCH_TEAMS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_TEAMS_FAILURE, { error: err.response.data }))
    },
    addTeam({ commit }, { team }) {
        commit(types.ADD_TEAM_REQUEST)
        APIteam.storeTeam(team)
            .then((response) => commit(types.ADD_TEAM_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_TEAM_FAILURE, { error: err.response.data }))
    },
    getTeamById({ commit }, { id }) {
        commit(types.GET_TEAM_BY_ID_REQUEST)
        APIteam.getTeamById(id)
            .then((response) => commit(types.GET_TEAM_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_TEAM_BY_ID_FAILURE, { error: err.response.data }))
    },
    editTeam({ commit }, { team }) {
        commit(types.EDIT_TEAM_REQUEST)
        APIteam.editTeam(team)
            .then((response) => commit(types.EDIT_TEAM_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_TEAM_FAILURE, { error: err.response.data }))
    },
    fetchAllTeams({ commit }) {
        commit(types.FETCH_ALL_TEAMS_REQUEST)
        APIteam.getAllTeams()
            .then((response) => commit(types.FETCH_ALL_TEAMS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_ALL_TEAMS_FAILURE, { error: err.response.data }))
    },
    fetchGetTeamsByGroupId({ commit }, { id }) {
        commit(types.GET_TEAMS_BY_GROUP_ID_REQUEST)
        APIgroup.getTeamsByGroupId(id)
            .then((response) => commit(types.GET_TEAMS_BY_GROUP_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_TEAMS_BY_GROUP_ID_FAILURE, { error: err.response.data }))
    },
    setTeamEmpty({ commit }){
        commit(types.SET_TEAM_EMPTY)
    },
    getPlayerById({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            commit(types.GET_PLAYERS_BY_TEAM_ID_REQUEST)
            APIteam.getPlayerByTeamId(id)
            .then((response) => {
                commit(types.GET_PLAYERS_BY_TEAM_ID_SUCCESS, { ...response.data })
                resolve(response.data)
            })
            .catch((err) => {
                commit(types.GET_PLAYERS_BY_TEAM_ID_FAILURE, { error: err.response.data })
                reject(err.response.data)
            })

        })
    }
    
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

