import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeAward(data) {
        return axios.post(`${ENDPOINT_PATH}/awards/`, data);
    },
    getAwards(params = null, polla_id) {
        return axios.post(`${ENDPOINT_PATH}/awards/datatable/${polla_id}`, params);
    },
    getAwardById(id) {
        return axios.get(`${ENDPOINT_PATH}/awards/${id}`)
    },
    editAward(team) {
        return axios.put(`${ENDPOINT_PATH}/awards/${team.id}`, team)
    },
    deleteAward(id) {
        return axios.delete(`${ENDPOINT_PATH}/awards/${id}`)
    }
};
