export const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST'
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
export const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE'

export const GET_NEWS_BY_ID_REQUEST = 'GET_NEWS_BY_ID_REQUEST'
export const GET_NEWS_BY_ID_SUCCESS = 'GET_NEWS_BY_ID_SUCCESS'
export const GET_NEWS_BY_ID_FAILURE = 'GET_NEWS_BY_ID_FAILURE'

export const ADD_NEWS_REQUEST = 'ADD_NEWS_REQUEST'
export const ADD_NEWS_SUCCESS = 'ADD_NEWS_SUCCESS'
export const ADD_NEWS_FAILURE = 'ADD_NEWS_FAILURE'

export const EDIT_NEWS_REQUEST = 'EDIT_NEWS_REQUEST'
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS'
export const EDIT_NEWS_FAILURE = 'EDIT_NEWS_FAILURE'
