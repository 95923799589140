import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeMatch(data) {
        return axios.post(`${ENDPOINT_PATH}/matches/`, data);
    },
    getMatches(params = null) {
        return axios.post(`${ENDPOINT_PATH}/matches/datatable`, params);
    },
    getMatchById(id) {
        return axios.get(`${ENDPOINT_PATH}/matches/${id}`)
    },
    editMatch(team) {
        return axios.put(`${ENDPOINT_PATH}/matches/${team.id}`, team)
    }

};
