import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storePollaCallejera(data) {
        return axios.post(`${ENDPOINT_PATH}/pollas/callejera`, data);
    },
    getPollaCallejeras(params = null) {
        return axios.post(`${ENDPOINT_PATH}/pollas/callejera/datatable`, params);
    },
    getPollaCallejeraById(id) {
        return axios.get(`${ENDPOINT_PATH}/pollas/callejera/${id}`)
    },
    editPollaCallejera(polla_callejera) {
        return axios.put(`${ENDPOINT_PATH}/pollas/callejera/${polla_callejera.id}`, polla_callejera)
    },
    getPollas(params= null) {
        return axios.get(`${ENDPOINT_PATH}/pollas/all`, {params})
    },
    getPositionsByPolla(params,id) {
        return axios.post(`${ENDPOINT_PATH}/pollas/positions/${id}`,{params})
    },
    addUserPollaPayment( id, data){
        return axios.post(`${ENDPOINT_PATH}/pollas/${id}/payment`, data)
    }
};
