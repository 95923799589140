import * as types from '../types/purchase'
import APIPurchase from '@/services/purchase'
import { saveAs } from 'file-saver';


const defaultState = {
    arrayPurchases: [],
    purchaseError: {},
    purchaseSusccesMessage: null
}


const mutations = {
    [types.FETCH_PURCHASE_REQUEST](state) {
        state.fetchingData = true
        state.purchaseError = null
    },
    [types.FETCH_PURCHASE_SUCCESS](state, arrayPurchases) {
        // console.log(data)
        state.fetchingData = false
        state.purchaseError = null
        state.arrayPurchases = arrayPurchases
    },
    [types.FETCH_PURCHASE_FAILURE](state, { error }) {
        state.fetchingData = false
        state.purchaseError = error
    },

}

const actions = {
    fetchPurchases({ commit }, { params, polla_type_id }) {
        commit(types.FETCH_PURCHASE_REQUEST)
        APIPurchase.getPurchases(params,polla_type_id)
            .then((response) => commit(types.FETCH_PURCHASE_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_PURCHASE_FAILURE, { error: err.response.data }))
    },
    exportPruchases({commit},{ params, polla_type_id }){
        APIPurchase.exportPurchase(params,polla_type_id)
            .then((response)=> {
                const data = new Blob([response.data], { type: response.headers['content-type'] });
                const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                saveAs(data, filename);
            }).catch((err)=> {
                console.log(err);
            })
    }
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

