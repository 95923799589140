import * as types from '../types/rule'
import APIrules from '@/services/rule'

const defaultState = {
    arrayRules: [],
    rule: {
    },
    ruleError: {},
    ruleSusccesMessage: null
}


const mutations = {
    [types.FETCH_RULES_REQUEST](state) {
        state.fetchingData = true
        state.ruleError = null
    },
    [types.FETCH_RULES_SUCCESS](state, arrayRules) {
        // console.log(data)
        state.fetchingData = false
        state.ruleError = null
        state.arrayRules = arrayRules
    },
    [types.FETCH_RULES_FAILURE](state, { error }) {
        state.fetchingData = false
        state.ruleError = error
    },
    [types.GET_RULE_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.ruleError = null
    },
    [types.GET_RULE_BY_ID_SUCCESS](state, { data, message }) {
        // console.log(data)
        state.fetchingData = false
        state.ruleError = null
        state.rule = data
    },
    [types.GET_RULE_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.ruleError = error
    },
    [types.EDIT_RULE_REQUEST](state) {
        state.fetchingData = true
        state.ruleError = null
        state.ruleSusccesMessage = null
    },
    [types.EDIT_RULE_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.ruleError = null
        state.rule = data
        state.ruleSusccesMessage = message
    },
    [types.EDIT_RULE_FAILURE](state, { error }) {
        state.fetchingData = false
        state.ruleError = error
        state.ruleSusccesMessage = null
    }

}

const actions = {
    fetchRules({ commit }, { params }) {
        commit(types.FETCH_RULES_REQUEST)
        APIrules.getRules(params)
            .then((response) => commit(types.FETCH_RULES_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_RULES_FAILURE, { error: err.response.data }))
    },
    getRuleById({ commit }, { id }) {
        commit(types.GET_RULE_BY_ID_REQUEST)
        APIrules.getRulesById(id)
            .then((response) => commit(types.GET_RULE_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_RULE_BY_ID_FAILURE, { error: err.response.data }))
    },
    editRule({ commit }, { rule }) {
        commit(types.EDIT_RULE_REQUEST)
        APIrules.editRules(rule)
            .then((response) => commit(types.EDIT_RULE_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_RULE_FAILURE, { error: err.response.data }))
    }
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};