import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeNews(data) {
        return axios.post(`${ENDPOINT_PATH}/news/`, data);
    },
    getNews(params = null) {
        return axios.post(`${ENDPOINT_PATH}/news/datatable`, params);
    },
    getNewsById(id) {
        return axios.get(`${ENDPOINT_PATH}/news/${id}`)
    },
    editNews(team) {
        return axios.put(`${ENDPOINT_PATH}/news/${team.id}`, team)
    }
};
