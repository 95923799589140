export const FETCH_RULES_REQUEST = 'FETCH_RULE_REQUEST'
export const FETCH_RULES_SUCCESS = 'FETCH_RULE_SUCCESS'
export const FETCH_RULES_FAILURE = 'FETCH_RULE_FAILURE'


export const GET_RULE_BY_ID_REQUEST = 'GET_RULE_BY_ID_REQUEST'
export const GET_RULE_BY_ID_SUCCESS = 'GET_RULE_BY_ID_SUCCESS'
export const GET_RULE_BY_ID_FAILURE = 'GET_RULE_BY_ID_FAILURE'


export const EDIT_RULE_REQUEST = 'EDIT_RULE_REQUEST'
export const EDIT_RULE_SUCCESS = 'EDIT_RULE_SUCCESS'
export const EDIT_RULE_FAILURE = 'EDIT_RULE_FAILURE'
