import * as types from '../types/polla'
import APIpolla from '@/services/polla'

const defaultState = {
    arrayPollaCallejeras: [],
    polla_callejera: {

    },
    pollaError: {},
    positions: [],
    pollasSelect: [],
    pollaSusccesMessage: null
}

const mutations = {
    [types.FETCH_POLLA_CALLEJERA_REQUEST](state) {
        state.fetchingData = true
        state.pollaError = null
    },
    [types.FETCH_POLLA_CALLEJERA_SUCCESS](state, arrayPollaCallejeras) {
        state.fetchingData = false
        state.pollaError = null
        state.arrayPollaCallejeras = arrayPollaCallejeras
    },
    [types.FETCH_POLLA_CALLEJERA_FAILURE](state, { error }) {
        state.fetchingData = false
        state.pollaError = error
    },
    [types.ADD_POLLA_CALLEJERA_REQUEST](state) {
        state.fetchingData = true
        state.pollaError = null
        state.pollaSusccesMessage = null
    },
    [types.ADD_POLLA_CALLEJERA_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.pollaError = null
        state.pollaSusccesMessage = message
    },
    [types.ADD_POLLA_CALLEJERA_FAILURE](state, { error }) {
        state.fetchingData = false
        state.pollaError = error
        state.pollaSusccesMessage = null
    },
    [types.EDIT_POLLA_CALLEJERA_REQUEST](state) {
        state.fetchingData = true
        state.pollaError = null
        state.pollaSusccesMessage = null
    },
    [types.EDIT_POLLA_CALLEJERA_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.pollaError = null
        state.pollaSusccesMessage = message
    },
    [types.EDIT_POLLA_CALLEJERA_FAILURE](state, { error }) {
        state.fetchingData = false
        state.pollaError = error
        state.pollaSusccesMessage = null
    },
    [types.GET_POLLA_CALLEJERA_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.pollaError = null
    },
    [types.GET_POLLA_CALLEJERA_BY_ID_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.pollaError = null
        state.polla_callejera = data
    },
    [types.GET_POLLA_CALLEJERA_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.pollaError = error
    },
    [types.FETCH_POLLAS_REQUEST](state) {
        state.fetchingData = true
        state.pollaError = null
    },
    [types.FETCH_POLLAS_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.pollaError = null
        console.log(data);
        state.pollasSelect = data
    },
    [types.FETCH_POLLAS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.pollaError = error
    },
    [types.FETCH_POSITIONS_BY_POLLA_REQUEST](state) {
        state.fetchingData = true
        state.pollaError = null
    },
    [types.FETCH_POSITIONS_BY_POLLA_SUCCESS](state, data) {
        state.fetchingData = false
        state.pollaError = null
        state.positions = data
    },
    [types.FETCH_POSITIONS_BY_POLLA_FAILURE](state, { error }) {
        state.fetchingData = false
        state.pollaError = error
    },
    [types.ADD_POLLA_PAYMENT_REQUEST](state) {
        state.fetchingData = true
    },
    [types.ADD_POLLA_PAYMENT_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.pollaError = null
        state.pollaSusccesMessage = message
    },
    [types.ADD_POLLA_PAYMENT_FAILURE](state, { error }) {
        state.fetchingData = false
        state.pollaError = error
        state.pollaSusccesMessage = null
    }
}

const actions = {
    fetchPollaCallejeras({ commit }, { params }) {
        commit(types.FETCH_POLLA_CALLEJERA_REQUEST)
        APIpolla.getPollaCallejeras(params)
            .then((response) => commit(types.FETCH_POLLA_CALLEJERA_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_POLLA_CALLEJERA_FAILURE, { error: err.response.data }))
    },
    addPollaCallejera({ commit }, { data }) {
        commit(types.ADD_POLLA_CALLEJERA_REQUEST)
        APIpolla.storePollaCallejera(data)
            .then((response) => commit(types.ADD_POLLA_CALLEJERA_SUCCESS, { ...response.data, message: response.data.message }))
            .catch((err) => commit(types.ADD_POLLA_CALLEJERA_FAILURE, { error: err.response.data }))
    },
    editPollaCallejera({ commit }, { polla_callejera }) {
        commit(types.EDIT_POLLA_CALLEJERA_REQUEST)
        APIpolla.editPollaCallejera(polla_callejera)
            .then((response) => commit(types.EDIT_POLLA_CALLEJERA_SUCCESS, { ...response.data, message: response.data.message }))
            .catch((err) => commit(types.EDIT_POLLA_CALLEJERA_FAILURE, { error: err.response.data }))
    },
    getPollaCallejeraById({ commit }, { id }) {
        commit(types.GET_POLLA_CALLEJERA_BY_ID_REQUEST)
        APIpolla.getPollaCallejeraById(id)
            .then((response) => commit(types.GET_POLLA_CALLEJERA_BY_ID_SUCCESS, { ...response.data, message: response.data.message }))
            .catch((err) => commit(types.GET_POLLA_CALLEJERA_BY_ID_FAILURE, { error: err.response.data }))
    },
    getPollas({ commit }, { params }) {
        commit(types.FETCH_POLLAS_REQUEST)
        APIpolla.getPollas(params)
            .then((response) => commit(types.FETCH_POLLAS_SUCCESS, { ...response }))
            .catch((err) => commit(types.FETCH_POLLAS_FAILURE, { error: err.response.data }))
    },
    getPositionsByPolla({ commit }, { params, id }) {
        console.log(params, id);
        commit(types.FETCH_POSITIONS_BY_POLLA_REQUEST)
        APIpolla.getPositionsByPolla(params, id)
            .then((response) => commit(types.FETCH_POSITIONS_BY_POLLA_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_POSITIONS_BY_POLLA_FAILURE, { error: err.response.data }))
    },
    addUserPollaPayment({ commit }, { id, data }) {
        return new Promise((resolve, reject) => {
            commit(types.ADD_POLLA_PAYMENT_REQUEST)
            APIpolla.addUserPollaPayment(id, data)
                .then((response) => {
                    commit(types.ADD_POLLA_PAYMENT_SUCCESS, { ...response.data, message: response.data.message })
                    resolve(response)
                })
                .catch((err) =>{
                    commit(types.ADD_POLLA_PAYMENT_FAILURE, { error: err.response.data })
                    reject(err.response.data)
                })
        })
    }


}


export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
}