import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeGroup(data) {
        return axios.post(`${ENDPOINT_PATH}/groups/`, data);
    },
    getGroups(params = null) {
        return axios.post(`${ENDPOINT_PATH}/groups/datatable`, params);
    },
    getGroupById(id) {
        return axios.get(`${ENDPOINT_PATH}/groups/${id}`)
    },
    editGroup(team) {
        return axios.put(`${ENDPOINT_PATH}/groups/${team.id}`, team)
    },
    getTeamsByGroupId(id) {
        return axios.get(`${ENDPOINT_PATH}/groups/${id}/teams`)
    }
};
