export const FETCH_TEAMS_REQUEST = 'FETCH_TEAMS_REQUEST'
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS'
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE'

export const FETCH_ALL_TEAMS_REQUEST = 'FETCH_ALL_TEAMS_REQUEST'
export const FETCH_ALL_TEAMS_SUCCESS = 'FETCH_ALL_TEAMS_SUCCESS'
export const FETCH_ALL_TEAMS_FAILURE = 'FETCH_ALL_TEAMS_FAILURE'

export const GET_TEAM_BY_ID_REQUEST = 'GET_TEAM_BY_ID_REQUEST'
export const GET_TEAM_BY_ID_SUCCESS = 'GET_TEAM_BY_ID_SUCCESS'
export const GET_TEAM_BY_ID_FAILURE = 'GET_TEAM_BY_ID_FAILURE'

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST'
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS'
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE'

export const EDIT_TEAM_REQUEST = 'EDIT_TEAM_REQUEST'
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS'
export const EDIT_TEAM_FAILURE = 'EDIT_TEAM_FAILURE'

export const GET_TEAMS_BY_GROUP_ID_REQUEST = 'GET_TEAMS_BY_GROUP_ID_REQUEST'
export const GET_TEAMS_BY_GROUP_ID_SUCCESS = 'GET_TEAMS_BY_GROUP_ID_SUCCESS'
export const GET_TEAMS_BY_GROUP_ID_FAILURE = 'GET_TEAMS_BY_GROUP_ID_FAILURE'

export const SET_TEAM_EMPTY = 'SET_TEAM_EMPTY'

export const GET_PLAYERS_BY_TEAM_ID_REQUEST = 'GET_PLAYERS_BY_TEAM_ID_REQUEST'
export const GET_PLAYERS_BY_TEAM_ID_SUCCESS = 'GET_PLAYERS_BY_TEAM_ID_SUCCESS'
export const GET_PLAYERS_BY_TEAM_ID_FAILURE = 'GET_PLAYERS_BY_TEAM_ID_FAILURE'



