export const FETCH_MATCHES_REQUEST = 'FETCH_MATCHES_REQUEST'
export const FETCH_MATCHES_SUCCESS = 'FETCH_MATCHES_SUCCESS'
export const FETCH_MATCHES_FAILURE = 'FETCH_MATCHES_FAILURE'

export const GET_MATCH_BY_ID_REQUEST = 'GET_MATCH_BY_ID_REQUEST'
export const GET_MATCH_BY_ID_SUCCESS = 'GET_MATCH_BY_ID_SUCCESS'
export const GET_MATCH_BY_ID_FAILURE = 'GET_MATCH_BY_ID_FAILURE'

export const ADD_MATCH_REQUEST = 'ADD_MATCH_REQUEST'
export const ADD_MATCH_SUCCESS = 'ADD_MATCH_SUCCESS'
export const ADD_MATCH_FAILURE = 'ADD_MATCH_FAILURE'

export const EDIT_MATCH_REQUEST = 'EDIT_MATCH_REQUEST'
export const EDIT_MATCH_SUCCESS = 'EDIT_MATCH_SUCCESS'
export const EDIT_MATCH_FAILURE = 'EDIT_MATCH_FAILURE'


