import * as types from '../types/coupon'
import APIcoupon from '@/services/coupon'

const defaultState = {
    arrayCoupons: [],
    coupon: {
    },
    couponError: {},
    couponSusccesMessage: null
}


const mutations = {

    [types.FETCH_COUPONS_REQUEST](state) {
        state.fetchingData = true
        state.couponError = null
    },
    [types.FETCH_COUPONS_SUCCESS](state, arrayCoupons) {
        // console.log(data)
        state.fetchingData = false
        state.couponError = null
        state.arrayCoupons = arrayCoupons
    },
    [types.FETCH_COUPONS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.couponError = error
    },
    [types.ADD_COUPON_REQUEST](state) {
        state.fetchingData = true
        state.couponError = null
        state.couponSusccesMessage = null
    },
    [types.ADD_COUPON_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.couponError = null
        state.couponSusccesMessage = message
    },
    [types.ADD_COUPON_FAILURE](state, { error }) {
        state.fetchingData = false
        state.couponError = error
        state.couponSusccesMessage = null
    },
    [types.EDIT_COUPON_REQUEST](state) {
        state.fetchingData = true
        state.couponError = null
        state.couponSusccesMessage = null
    },
    [types.EDIT_COUPON_SUCCESS](state, { data, message }) {

        state.fetchingData = false
        state.couponError = null
        state.couponSusccesMessage = message
    },
    [types.EDIT_COUPON_FAILURE](state, { error }) {
        state.fetchingData = false
        state.couponError = error
        state.couponSusccesMessage = null
    },
    [types.GET_COUPON_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.couponError = null
    },
    [types.GET_COUPON_BY_ID_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.couponError = null
        state.coupon = data
    },
    [types.GET_COUPON_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.couponError = error
    },

}


const actions = {
    fetchCoupons({ commit }, { params }) {
        commit(types.FETCH_COUPONS_REQUEST)
        APIcoupon.getCoupons(params)
            .then((response) => commit(types.FETCH_COUPONS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_COUPONS_FAILURE, { error: err.response.data }))
    },
    addCoupon({ commit }, { coupon }) {
        commit(types.ADD_COUPON_REQUEST)
        APIcoupon.storeCoupon(coupon)
            .then((response) => commit(types.ADD_COUPON_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_COUPON_FAILURE, { error: err.response.data }))
    },
    getCouponById({ commit }, { id }) {
        commit(types.GET_COUPON_BY_ID_REQUEST)
        APIcoupon.getCouponById(id)
            .then((response) => commit(types.GET_COUPON_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_COUPON_BY_ID_FAILURE, { error: err.response.data }))
    },
    editCoupon({ commit }, { coupon }) {
        commit(types.EDIT_COUPON_REQUEST)
        APIcoupon.editCoupon(coupon)
            .then((response) => commit(types.EDIT_COUPON_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_COUPON_FAILURE, { error: err.response.data }))
    },
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};