import * as types from '../types/match'
import APImatch from '@/services/match'

const defaultState = {
    matches: [],
    match: {
    },
    matchError: {},
    matchSusccesMessage: null
}


const mutations = {

    [types.FETCH_MATCHES_REQUEST](state) {
        state.fetchingData = true
        state.matchError = null
    },
    [types.FETCH_MATCHES_SUCCESS](state, matches) {
        // console.log(data)
        state.fetchingData = false
        state.matchError = null
        state.matches = matches
    },
    [types.FETCH_MATCHES_FAILURE](state, { error }) {
        state.fetchingData = false
        state.matchError = error
    },
    [types.ADD_MATCH_REQUEST](state) {
        state.fetchingData = true
        state.matchError = null
        state.matchSusccesMessage = null
    },
    [types.ADD_MATCH_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.matchError = null
        state.matchSusccesMessage = message
    },
    [types.ADD_MATCH_FAILURE](state, { error }) {
        state.fetchingData = false
        state.matchError = error
        state.matchSusccesMessage = null
    },
    [types.EDIT_MATCH_REQUEST](state) {
        state.fetchingData = true
        state.matchError = null
        state.matchSusccesMessage = null
    },
    [types.EDIT_MATCH_SUCCESS](state, { data, message }) {

        state.fetchingData = false
        state.matchError = null
        state.matchSusccesMessage = message
    },
    [types.EDIT_MATCH_FAILURE](state, { error }) {
        state.fetchingData = false
        state.matchError = error
        state.matchSusccesMessage = null
    },
    [types.GET_MATCH_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.matchError = null
    },
    [types.GET_MATCH_BY_ID_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.matchError = null
        state.match = data
    },
    [types.GET_MATCH_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.matchError = error
    }

}


const actions = {
    fetchMatches({ commit }, { params }) {
        commit(types.FETCH_MATCHES_REQUEST)
        APImatch.getMatches(params)
            .then((response) => commit(types.FETCH_MATCHES_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_MATCHES_FAILURE, { error: err.response.data }))
    },
    addMatch({ commit }, { match }) {
        commit(types.ADD_MATCH_REQUEST)
        APImatch.storeMatch(match)
            .then((response) => commit(types.ADD_MATCH_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_MATCH_FAILURE, { error: err.response.data }))
    },
    getMatchById({ commit }, { id }) {
        commit(types.GET_MATCH_BY_ID_REQUEST)
        APImatch.getMatchById(id)
            .then((response) => commit(types.GET_MATCH_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_MATCH_BY_ID_FAILURE, { error: err.response.data }))
    },
    editMatch({ commit }, { match }) {
        commit(types.EDIT_MATCH_REQUEST)
        APImatch.editMatch(match)
            .then((response) => commit(types.EDIT_MATCH_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_MATCH_FAILURE, { error: err.response.data }))
    }
   
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

