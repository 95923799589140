import APIutil from '@/services/util'
import * as types from '../types/util'


const defaultState = {
    stadiums: [],
    continents: [],
    group_phases: [],
    positions: [],
    photo_url: null,
    errorMaxFotos: null,
    objImage: {},
    match_results: [],
    polla_scopes: [],
    award_positions: [],
}

const mutations = {

    [types.FETCH_STADIUMS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_STADIUMS_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.error = null
        state.stadiums = data
    },
    [types.FETCH_STADIUMS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_CONTINENTS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_CONTINENTS_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.error = null
        state.continents = data
    },
    [types.FETCH_CONTINENTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_GROUP_PHASES_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_GROUP_PHASES_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.error = null
        state.group_phases = data
        //edit partner commit
    },
    [types.FETCH_GROUP_PHASES_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.UPLOAD_IMAGE_REQUEST](state) {
        state.fetchingData = true
        state.error = null
        state.objImage = {}
    },
    [types.UPLOAD_IMAGE_SUCCES](state, { file_url, file }) {
        state.fetchingData = false
        state.error = null
        state.objImage = {
            file_url: file_url,
            file: file
        }
    },
    [types.UPLOAD_IMAGE_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_POSITIONS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_POSITIONS_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.error = null
        state.positions = data
    },
    [types.FETCH_POSITIONS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.GET_MATCH_RESULTS_REQUEST](state) {
        state.fetchingData = true
        state.matchError = null
    },
    [types.GET_MATCH_RESULTS_SUCCESS](state, { data, message }) {
        state.fetchingData = false
        state.matchError = null
        state.match_results = data
    },
    [types.GET_MATCH_RESULTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.matchError = error
    },
    [types.FETCH_POLLA_SCOPES_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_POLLA_SCOPES_SUCCESS](state, { data,message }) {
        state.fetchingData = false
        state.error = null
        state.polla_scopes = data
    },
    [types.FETCH_POLLA_SCOPES_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_AWARD_POSITIONS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_AWARD_POSITIONS_SUCCESS](state, { data }) {
        state.fetchingData = false
        state.error = null
        state.award_positions = data
    },
    [types.FETCH_AWARD_POSITIONS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    }
}

const actions = {
    fetchStadiums({ commit }) {
        commit(types.FETCH_STADIUMS_REQUEST)
        APIutil.getStadiums()
            .then((response) => commit(types.FETCH_STADIUMS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_CONTINENTS_FAILURE, { error: error.response.data }))
    },
    fetchContinents({ commit }) {
        commit(types.FETCH_CONTINENTS_REQUEST)
        APIutil.getContinents()
            .then((response) => commit(types.FETCH_CONTINENTS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_CONTINENTS_FAILURE, { error: error.response.data }))
    },
    fetchGroupPhases({ commit }) {
        commit(types.FETCH_GROUP_PHASES_REQUEST)
        APIutil.getGroupPhases()
            .then((response) => commit(types.FETCH_GROUP_PHASES_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_GROUP_PHASES_FAILURE, { error: error.response.data }))
    },
    uploadImage({ commit }, { data }) {
        return new Promise((resolve, reject) => {
            commit(types.UPLOAD_IMAGE_REQUEST)
            APIutil.uploadImage(data)
                .then((response) =>{
                    commit(types.UPLOAD_IMAGE_SUCCES, { ...response.data })
                    resolve(response.data)
                })
                .catch((error) =>{
                    commit(types.UPLOAD_IMAGE_FAILURE, { error: error.response.data })
                    reject(error.response.data)
                })

        })
    },
    fetchPositions({ commit }) {
        commit(types.FETCH_POSITIONS_REQUEST)
        APIutil.getPositions()
            .then((response) => commit(types.FETCH_POSITIONS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_POSITIONS_FAILURE, { error: error.response.data }))
    },
    fetchMatchResults({ commit }) {
        commit(types.GET_MATCH_RESULTS_REQUEST)
        APIutil.getMatchResults()
            .then((response) => commit(types.GET_MATCH_RESULTS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_MATCH_RESULTS_FAILURE, { error: err.response.data }))
    },
    fetchPollaScopes({ commit }) {
        commit(types.FETCH_POLLA_SCOPES_REQUEST)
        APIutil.getPollaScopes()
            .then((response) => commit(types.FETCH_POLLA_SCOPES_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_POLLA_SCOPES_FAILURE, { error: error.response.data }))
    },
    fetchAwardPositions({ commit }) {
        commit(types.FETCH_AWARD_POSITIONS_REQUEST)
        APIutil.getAwardPositions()
            .then((response) => commit(types.FETCH_AWARD_POSITIONS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_AWARD_POSITIONS_FAILURE, { error: error.response.data }))
    }
}



export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

